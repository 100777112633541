document.addEventListener("DOMContentLoaded", function () {
    function lazyLoad() {
        const lazyElements = document.querySelectorAll('.lazy:not([lazy-liosha])');

        lazyElements.forEach(element => {
            // Initialize styles for the appearance animation
            element.style.opacity = '0';
            element.style.transform = 'translateY(20px)';
            element.style.transition = 'opacity 1s ease, transform 1s ease';

            // Save the original src in data attributes and clear it
            if (element.tagName === 'IMG' || element.tagName === 'IFRAME') {
                element.setAttribute('data-src', element.src);
                element.src = ''; 
            } else if (element.style.backgroundImage) {
                const bgImageUrl = element.style.backgroundImage.match(/url\(["']?([^"']*)["']?\)/)[1];
                element.setAttribute('data-src', bgImageUrl);
                element.style.backgroundImage = ''; 
            }

            // Mark the element as processed
            element.setAttribute('lazy-liosha', 'true');

            // Create and configure the observer
            const observer = new IntersectionObserver(entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const lazyElement = entry.target;
                        // Load the resource upon intersection
                        if (lazyElement.tagName === 'IMG' || lazyElement.tagName === 'IFRAME') {
                            lazyElement.src = lazyElement.getAttribute('data-src');
                        } else {
                            lazyElement.style.backgroundImage = `url(${lazyElement.getAttribute('data-src')})`;
                        }

                        // Animation after loading
                        lazyElement.onload = () => {
                            lazyElement.style.opacity = '1';
                            lazyElement.style.transform = 'translateY(0)';
                        };

                        if (!lazyElement.tagName.match(/IMG|IFRAME/)) {
                            lazyElement.style.opacity = '1';
                            lazyElement.style.transform = 'translateY(0)';
                        }

                        // Stop observing the element
                        observer.unobserve(lazyElement); 
                    }
                });
            });

            // Start observing each element
            observer.observe(element); 
        });
    }

    // Initialize lazy loading
    lazyLoad();

    // Expose the function for external invocation if needed
    window.lazyLoad = lazyLoad;
});
